import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../services/storage.service';

export const INTRO_KEY = 'intro-seen';

@Injectable({
  providedIn: 'root'
})

export class IntroGuard  {
 
  constructor(private router: Router,
    private storage: StorageService) { }
 
  async canLoad(): Promise<boolean> {

     const hasSeenIntro = await this.storage.getString( INTRO_KEY);   

  
      if (hasSeenIntro && (hasSeenIntro.value === 'true')) {

        return true;
      } else {
        this.router.navigateByUrl('/intro', { replaceUrl:true });
        return false;
      }
     

  }
}